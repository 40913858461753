import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";

export default {
  data() {
    return {
      taskData: [],
      type: "",
      sort_direction: "D",
      sort_by: "D",
      userId: EncryptUtility.localStorageDecrypt("userID"),
      routeId: "",
      encType: "",
      taskDetails: [],
      backSlash: true,
      headers: [
        {
          text: "Serial Number",
          align: "start",
          value: "serialno",
          class: "primary customwhite--text",
        },
        {
          text: "RITM",
          value: "ritm",
          class: "primary customwhite--text",
        },
        {
          text: "Task ID",
          value: "taskid",
          class: "primary customwhite--text",
        },
        {
          text: "Bin",
          value: "bin",
          class: "primary customwhite--text",
        },
        {
          text: "LPN",
          value: "lpn",
          class: "primary customwhite--text",
        },
        {
          text: "Date",
          value: "opendate",
          class: "primary customwhite--text",
        },
        {
          text: "Status",
          value: "status_desc",
          class: "primary customwhite--text",
        },
        {
          text: "Location",
          value: "loc",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "action",
          class: "primary customwhite--text",
        },
      ],
      json_fields: {
        Serial_No: "serialno",
        RITM: "ritm",
        Task_ID: "taskid",
        Bin: "bin",
        LPN: "lpn",
        Date: "opendate",
        Status: "status_desc",
        Location: "loc",
      },
      excelName: "",
      taskSearch: "",
      orderItems: [
        { name: "ASC", value: "A" },
        { name: "DESC", value: "D" },
      ],
      tableRows: [],
      rowId: 0,
      sortItems: [
        { name: "Date", value: "D" },
        { name: "Bin", value: "B" },
      ],
      detailsDialog: false,
      nextDialog: false,
      purgeDialog: false,
      closeReqDialog: false,
      cancelDialog: false,
      itemLoaded: false,
      selectedItemDetails: null,
      initialLPN: false,
      validLPN: false,
      lpn_id: 0,
      selectedItemDetailsSerialNumber: "",
      validSerialNo: false,
      lpnRules: [(value) => /^[\d-]+$/.test(value) || "Invalid LPN"],
      gotNextLPN: false,
      nearestBins: [],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    this.routeId = this.$route.params.id;
    this.encType = this.$route.params.type;
    this.type = atob(this.encType);
  },
  mounted() {
    this.getRows();
    this.getTaskList();
  },
  methods: {
    //Reset the screen
    resetFunction() {
      this.taskSearch = "";
      this.initialLPN = false;
      this.validLPN = false;
      this.selectedItemDetailsSerialNumber = "";
      this.validSerialNo = false;
      this.gotNextLPN = false;
      document.getElementById("button-text-sort").innerHTML = "Date";
      document.getElementById("button-text-order").innerHTML = "DESC";
      document.getElementById("button-text-row").innerHTML = "ALL ROWS";
      this.sort_direction = "D";
      this.sort_by = "D";
      this.rowId = 0;
      this.getRows();
      this.getTaskList();
    },
    //Get Task List
    getTaskList() {
      this.taskData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        user_key: this.userId,
        type: this.type,
        SearchStr: "",
        sort_direction: this.sort_direction,
        sort_by: this.sort_by,
        row_key: this.rowId,
      };
      this.axios
        .post("/usb/usb_pulled_hardware_queue", searchObject)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== null) {
              this.taskData = responseData;
            }
            if (this.taskData) {
              for (var i = 0; i < this.taskData.length; i++) {
                let date = new Date(this.taskData[i].opendate + "Z");
                this.taskData[i].opendate =
                  (date.getMonth() + 1 < 10 ? "0" : "") +
                  (date.getMonth() + 1) +
                  "/" +
                  (date.getDate() < 10 ? "0" : "") +
                  date.getDate() +
                  "/" +
                  date.getFullYear() +
                  " " +
                  date.toLocaleString("en-US", { hour: "2-digit", minute: "numeric", second: "numeric", hour12: true });
              }
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Get rows
    getRows() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/usb/usb_pulled_hardware_getrows?user_key=${this.userId}&type=${this.type}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.tableRows = responseData;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Show certain rows
    rowsOption(row) {
      this.rowId = row.row_id;
      this.getTaskList();
      document.getElementById("button-text-row").innerHTML = row.row;
    },
    // Sort data table
    sortOption(type, value) {
      this.sort_by = value;
      this.getTaskList();
      document.getElementById("button-text-sort").innerHTML = type;
    },
    // Order data table
    orderOption(type, value) {
      this.sort_direction = value;
      this.getTaskList();
      document.getElementById("button-text-order").innerHTML = type;
    },
    // Edit task details
    editItem(item, binId) {
      this.itemLoaded = false;
      this.detailsDialog = true;
      document.activeElement.blur();
      this.selectedItem = item;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let passed_ph_id = 0;
      if (item !== null) {
        passed_ph_id = item.ph_id;
      }
      let searchObject = {
        user_key: this.userId,
        ph_id: passed_ph_id,
        bin_id: binId,
      };
      this.axios
        .post("/usb/usb_pulled_hardware_details_by_ph_id", searchObject)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.selectedItemDetails = responseData[0];
            if (binId !== 0) {
              this.selectedItem = this.selectedItemDetails;
            }
            this.initialLPN = this.selectedItemDetails.intransit_lpn !== "";
            this.validLPN = this.selectedItemDetails.intransit_lpn !== "";
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.itemLoaded = true;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Check for serial number validation for styling
    updateSerialNo() {
      this.selectedItemDetailsSerialNumber = this.selectedItemDetailsSerialNumber.toUpperCase();
      this.validSerialNo = this.$refs.editSerialNo.validate();
    },
    // Get LPN if not provided
    getNextLPN() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/usb/usb_pulled_hardware_get_lpn?user_key=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.lpn_id = responseData[0].lpn_id;
            this.selectedItemDetails.intransit_lpn = responseData[0].lpn;
            this.validLPN = true;
            this.gotNextLPN = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Validate entered LPN, testing is needed
    validateLPN() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        user_key: this.userId,
        lpn: this.selectedItemDetails.intransit_lpn,
      };
      this.axios
        .post("/usb/usb_pulled_hardware_validate_lpn", searchObject)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.validLPN = true;
            this.lpn_id = responseData.lpn_id;
            this.submitTaskDetails();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.selectedItemDetails.intransit_lpn = "";
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Submit task details
    submitTaskDetails() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        user_key: this.userId,
        ph_id: this.selectedItem.ph_id,
        lpn_id: this.lpn_id,
      };
      this.axios
        .post("/usb/usb_pulled_hardware_move_intransit", searchObject)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            let apiMessage = JSON.stringify(responseData[0].API_Message[0]);
            this.sendClosureMessage(apiMessage);
            if (response.data.body.status === "True") {
              this.nearestBins = responseData[0].Body;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let successMessage = responseData[0].Message;
            if (this.nearestBins === undefined) {
              successMessage += ". There are no near bins.";
            } else {
              if (this.nearestBins.length === 0) {
                successMessage += ". There are no near bins.";
              }
            }
            let Alert = {
              type: "success",
              isShow: true,
              message: successMessage,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.getTaskList();
            this.nextDialog = true;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Send closure message to USB upon submitting
    sendClosureMessage(apiMessage) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        json: apiMessage,
      };
      this.axios
        .post("/ex/x_usb_updates", searchObject)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            console.log(responseData);
            // LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            // Show success message?
            //
            // let successMessage = responseData.Message;
            // let Alert = {
            //   type: "success",
            //   isShow: true,
            //   message: successMessage,
            // };
            // this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Move PH to discrepancy
    moveToDiscrepancy() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        user_key: this.userId,
        ph_id: this.selectedItem.ph_id,
        lpn_id: this.lpn_id,
      };
      this.axios
        .post("/usb/usb_pulled_hardware_discrepancy_move", searchObject)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (response.data.body.status === "True") {
              this.nearestBins = responseData[0].Body;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let successMessage = responseData[0].Message;
            if (this.nearestBins === undefined) {
              successMessage += ". There are no near bins.";
            } else {
              if (this.nearestBins.length === 0) {
                successMessage += ". There are no near bins.";
              }
            }
            let Alert = {
              type: "success",
              isShow: true,
              message: successMessage,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.getTaskList();
            this.nextDialog = true;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    // Refresh details dialog
    refreshDetails() {
      this.initialLPN = false;
      this.validLPN = false;
      this.selectedItemDetailsSerialNumber = "";
      this.validSerialNo = false;
      this.gotNextLPN = false;
    },
    // Finalize purge request
    finalizePurge() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        user_key: this.userId,
        ph_id: this.selectedItem.ph_id,
      };
      this.axios
        .post("/usb/usb_pulled_hardware_purge", searchObject)
        .then((response) => {
          if (response.status == 200) {
            this.purgeDialog = false;
            this.cancelDialog = false;
            this.detailsDialog = false;
            this.getTaskList();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
      this.purgeDialog = false;
      this.cancelDialog = false;
    },
    // Close Request for Discrepancies
    closeRequest() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("/usb/usb_pulled_hardware_DiscrepClose?user_key=" + this.userId + "&ph_id=" + this.selectedItem.ph_id)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            let apiMessage = JSON.stringify(responseData[0].API_Message[0]);
            this.sendClosureMessage(apiMessage);
            this.closeReqDialog = false;
            this.detailsDialog = false;
            this.getTaskList();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let successMessage = responseData[0].Message;
            let Alert = {
              type: "success",
              isShow: true,
              message: successMessage,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
      this.closeReqDialog = false;
    },
    // Populate dialog with next bin details
    nextBin(binId) {
      this.refreshDetails();
      this.editItem(null, binId);
      this.nextDialog = false;
    },
    // File name for Downloading excel
    fileName() {
      let taskType = "Open";
      if (this.type === "E") {
        taskType = "Exception";
      } else if (this.type === "D") {
        taskType = "Discrepancy";
      }
      this.excelName =
        "Pulled_Hardware_Queue_" +
        taskType +
        "_" +
        new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") +
        ".xls";
    },
  },
  components: {
    breadcrumbComp,
  },
};
